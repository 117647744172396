import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { HomePage, NoRoute } from './app/index';
import ServicePage from './app/servicePage';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/home' element={<HomePage />} />
        <Route path='/services/:serviceID' element={<ServicePage />} />
        <Route path='*' element={<NoRoute />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
