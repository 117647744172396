import React from 'react';

const PARTNERS = [
  { img: 'Cisco.png' },
  { img: 'cisco-meraki-logo.svg' },
  { img: 'office365-logo.png' },
  { img: 'vm-logo.png' },
  { img: 'ubiquiti.svg', width: 90 }
];

const Partners = () => {
  return (
    <section id='partners' className=''>
      <div className='container mx-auto px-4 my-10'>
        <h2 className='text-4xl font-bold w-full text-center mb-5 underline underline-offset-8'>Technology We Work With</h2>
        <div className='flex flex-wrap justify-center items-center'>
          {PARTNERS.map((part, index) => (
            <div key={index} className='w-full sm:w-2/5 md:w-4/12 xl:w-1/5 h-36 flex items-center justify-center overflow-hidden'>
              <img
                src={'assets/partners/' + part.img}
                alt={'Our Partner'}
                className={'cursor-pointer object-contain ' + (part?.width ? 'max-h-[60%_!important] max-w-[90%_!important]' : 'max-w-[250px] max-h-full')}
              ></img>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Partners;
