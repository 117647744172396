import React from 'react';
import AboutSection from './aboutSection';
import ContactSection from './contactSection';
import Footer from './footer';
import Header from './header';
import ServiceSection from './serviceSection';
import Banner from '../utils/owlCrousel';
import Partners from './partners';

const HomePage = () => {
  return (
    <main>
      <Header />

      <div className='relative overflow-x-hidden flex items-center justify-center'>
        <div
          className='absolute top-0 w-full h-full bg-right bg-cover'
          style={{
            backgroundImage: "url('/assets/image_2.jpg')"
          }}
        >
          <span id='blackOverlay' className='w-full h-full absolute opacity-95 bg-black'></span>
        </div>
        <Banner />
        <div className='container relative mx-auto h-full w-full'>
          {/* <div className='items-center flex flex-wrap'>
            <div className='w-full lg:w-6/12 px-4 ml-auto mr-auto text-center'>
              <div className='md:pr-12'>
                <h1 className='text-white font-semibold text-5xl'>
                  Welcome to <span className='text-mainColor'>DHS Technology</span>,
                </h1>
                <p className='mt-4 text-lg text-gray-300'>
                  Here we are, full of passionate people delivering excellence to our clients, while helping them live stress-free life of keeping their places
                  clean and tidy with our bunch of cleaning services.
                </p>
              </div>
            </div>
          </div> */}
        </div>
        {/* <div className='top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden' style={{ height: '70px' }}>
          <svg
            className='absolute bottom-0 overflow-hidden'
            xmlns='http://www.w3.org/2000/svg'
            preserveAspectRatio='none'
            version='1.1'
            viewBox='0 0 2560 100'
            x='0'
            y='0'
          >
            <polygon className='text-gray-50 fill-current' points='2560 0 2560 100 0 100'></polygon>
          </svg>
        </div> */}
      </div>

      <AboutSection />
      <ServiceSection />
      <Partners />
      <ContactSection />
      <Footer />
    </main>
  );
};

export default HomePage;
