import React, { useCallback, useEffect, useState } from 'react';

const AboutSection = () => {
  // const [showService, setShowService] = useState(SERVICES[0]?.name || '');
  const [index, setIndex] = useState(1);

  const changeText = useCallback(() => {
    // setShowService(SERVICES[index]?.name || '');
    setIndex(index + 1);
  }, [index]);

  useEffect(() => {
    const interval = setTimeout(changeText, 5000);

    return () => clearTimeout(interval);
  }, [index, changeText]);

  return (
    <section id='about' className='pb-20 bg-gray-50'>
      <div className='container mx-auto px-4'>
        {/* <div className='flex justify-center -mt-32'>
          <div className='lg:pt-12 pt-6 w-full md:w-8/12 xl:w-6/12 px-4 text-center'>
            <div className='relative h:72 md:h-64 px-4 py-5 flex flex-col-reverse sm:flex-row md:gap-4 break-words bg-white w-full mb-8 shadow-lg rounded-lg'>
              <div className='flex flex-col gap-2 justify-center items-center sm:w-6/12 h-6/12 '>
                <h2 className='text-xl font-semibold text-gray-600'>What we do</h2>
                <div className='h-20 flex items-center justify-center bg-rose-400 rounded overflow-hidden'>
                  <h1
                    className=' p-2 text-2xl font-semibold font-mono antialiased uppercase text-white'
                    style={{ width: '236px', animation: 'slideIn 5s', animationIterationCount: 'infinite' }}
                  >
                    {showService}
                  </h1>
                </div>
              </div>
              <div className='md:w-6/12 text-white p-3 text-center inline-flex items-center justify-center mb-5'>
                <img className='text-4xl w-4/12 sm:w-8/12' src='./assets/illustrations/illus.svg' alt='illustration'></img>
              </div>
            </div>
          </div>
        </div> */}
        <div className='flex flex-wrap pt-12 md:pt-16'>
          <div className='w-full md:w-7/12 px-4 mb-8 md:mb-0 pt-4'>
            <h3 className='text-3xl mb-2 font-semibold leading-normal'>About Us</h3>
            <p className='text-lg font-light leading-relaxed mt-4 mb-4 text-black text-justify'>
              At DHS Technology, we are dedicated to providing exceptional managed IT services to businesses of all sizes. With our expertise and cutting-edge
              technology solutions, we aim to streamline your IT operations, enhance your productivity, and ensure the security and efficiency of your systems.
              <br></br>At the core of our success is our team of highly skilled and experienced IT professionals. Our engineers, technicians, and consultants
              are passionate about technology and have in-depth knowledge across a wide range of IT disciplines. They are committed to delivering personalized
              solutions tailored to meet your specific business needs.
              {/* <br></br>We offer a comprehensive suite of managed IT services designed to keep your
              business running smoothly. Whether you require assistance with network management, cloud computing, data backup and recovery, cybersecurity, or IT
              consulting, our team is here to help. We proactively monitor and manage your IT infrastructure, resolving issues before they impact your
              operations. */}
            </p>
            {/* <a href='/contact' className='font-bold text-gray-800 mt-8'>
              Contact Us!
            </a> */}
          </div>

          <div className='w-full md:w-5/12 px-4 '>
            <div className='h-full w-full relative flex flex-col items-center justify-center min-w-0 break-words mb-6 rounded-lg'>
              <img alt='...' src='/assets/image_6.jpg' className='w-full h-64 object-left object-cover align-middle shadow-lg rounded-lg' />
              {/* <blockquote className='relative p-8 mb-4'>
                <svg
                  preserveAspectRatio='none'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 583 95'
                  className='absolute left-0 w-full block'
                  style={{
                    height: '95px',
                    top: '-94px'
                  }}
                >
                  <polygon points='-30,95 583,95 583,65' className='text-mainColor fill-current'></polygon>
                </svg>
                <h4 className='text-xl font-bold text-white'>Top Valued Services</h4>
                <p className='text-md font-light mt-2 text-white'>
                  We provide the best valued services that are market competitive and affordable. Here at GTM Facilities Services, you can expect reliable and
                  finest cleaning services.
                </p>
              </blockquote> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
