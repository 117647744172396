import React from 'react';
import { useNavigate } from 'react-router-dom';

const NoRoute = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className='w-full'>
        <div className='container mx-auto'>
          <div className='p-10 flex flex-col gap-5 justify-center items-center'>
            <div className='py-5 flex gap-3'>
              <h2 className='text-4xl border-r-4 border-slate-500 pr-3 align-middle'>404</h2>
              <h2 className='text-3xl'>Page Not Found</h2>
            </div>
            <button className='p-4 bg-slate-700 text-white rounded text-xl shadow-lg' onClick={() => navigate('/')}>
              Go Back
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default NoRoute;
