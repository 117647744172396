import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const TOPICS = [
  { name: 'Managed IT Services', image: 'assets/image_4.jpg' },
  { name: 'IT Consulting and Project', image: 'assets/itproject_mainpage.jpeg' },
  { name: '24/7 Help Desk and Monitoring', image: 'assets/help_desk.jpeg' }
];

// className "owl-theme" is optional
const Banner = () => {
  // const [windowSize, setWindowSize] = useState(2);
  // window.addEventListener('resize', () => {
  //   if (window.innerWidth <= 480) {
  //     setWindowSize(1);
  //   } else if (window.innerWidth > 480 && window.innerWidth <= 996) {
  //     setWindowSize(2);
  //   } else {
  //     setWindowSize(3);
  //   }
  // });
  return (
    <OwlCarousel
      className='owl-theme overflow-hidden relative min-w-full min-h-full flex items-center justify-center'
      loop
      margin={10}
      stageOuterClass={'overflow-visible'}
      nav
      navClass={['text-slate-200 text-6xl mr-2 hover:text-slate-400', 'text-slate-200 text-6xl ml-2 hover:text-slate-400']}
      items={1}
      dotsClass={'owl-dots absolute bottom-0 left-0 w-full'}
      navContainerClass={'absolute top-[45%] left-0 owl-nav w-full flex items-center justify-between px-2'}
      navSpeed={1000}
      autoplay={true}
      autoplaySpeed={2000}
      autoplayTimeout={8000}
      autoplayHoverPause
    >
      {TOPICS.map((topic, i) => (
        <div key={i} className={`item w-full min-h-[75vh] relative flex justify-center items-center bg-cover bg-no-repeat`}>
          <img src={topic.image} alt='Banner pictures...' className='absolute top-0 left-0 h-full w-full object-cover brightness-90'></img>
          <div className='absolut top-0 left-0 flex justify-center items-center p-10 h-[220px] max-w-[600px] z-10'>
            <h2
              className='text-6xl text-center text-white font-bold'
              style={{
                textShadow: 'rgb(0 0 0) 1px 1px 1px, rgb(0 0 0) 1px 2px 1px, rgb(0 0 0) 1px 3px 1px, rgb(0 0 0) 1px 4px 1px, rgb(0 0 0) 1px 5px 1px'
              }}
            >
              {topic.name}
            </h2>
          </div>
        </div>
      ))}
    </OwlCarousel>
  );
};

export default Banner;
