import React, { useEffect, useRef, useState } from 'react';
import SERVICES from '../data/availableServices.json';
import Modal from '../utils/serviceModal';
import { IoClose } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

const ServiceSection = () => {
  const [openModal, setOpenModal] = useState(false);
  const modalRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const setModalOpen = (e) => {
      if (openModal && modalRef.current && !modalRef.current.contains(e.target)) {
        setOpenModal(false);
      }
    };
    document.addEventListener('click', setModalOpen, true);

    return document.removeEventListener('click', setModalOpen);
  }, [openModal]);
  return (
    <section id='services' className='relative py-16 bg-slate-200'>
      {/* <div className='bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20' style={{ height: '80px' }}>
        <svg
          className='absolute bottom-0 overflow-hidden'
          xmlns='http://www.w3.org/2000/svg'
          preserveAspectRatio='none'
          version='1.1'
          viewBox='0 0 2560 100'
          x='0'
          y='0'
        >
          <polygon className='text-gray-300 fill-current' points='2560 0 2560 100 0 100'></polygon>
        </svg>
      </div> */}

      <div className='container mx-auto px-4 relative'>
        <div
          className={'absolute top-0 left-0 w-full h-full z-10  backdrop-blur-sm bg-opacity-20 ' + (openModal ? 'flex justify-center items-center' : 'hidden')}
        >
          <div ref={modalRef} className='relative max-w-[600px] p-5 md:p-10 flex items-center justify-center mx-auto rounded-xl bg-slate-50 shadow-lg'>
            <button className='mr-auto p-3 absolute top-2 right-2' onClick={() => setOpenModal(!openModal)}>
              <IoClose size={'1.5rem'} className={'text-red-400 transition-all duration-500'} />
            </button>
            <Modal service={null} />
          </div>
        </div>
        <div className='flex flex-col items-center justify-center px-4 text-center'>
          <h3 className='text-4xl font-semibold'>Our Services</h3>
          <p className='mt-4 text-lg leading-relaxed text-gray-600'></p>
          <div className='flex flex-wrap w-full justify-center gap-5 mt-10 mb-5'>
            {SERVICES.map((item, i) => {
              return (
                <div
                  key={i}
                  onClick={() => {
                    navigate(`/services/${item.id}`);
                  }}
                  className='h-80 w-full group cursor-pointer relative md:w-72 bg-slate-300 rounded overflow-hidden group shadow-lg border border-gray-400 '
                >
                  <div className='z-5 absolute h-3/5 w-full text-white shadow-lg overflow-hidden sm:group-hover:scale-125 transition-all duration-500'>
                    <span className='absolute w-full h-full top-0 left-0 bg-gray-800 opacity-25'></span>
                    <img className='object-cover object-center w-full h-full' src={item.image} alt='...'></img>
                  </div>
                  <div className='z-6 absolute bottom-0 h-2/5 w-full p-3 flex flex-col gap-3 justify-center items-center bg-sky-400 backdrop-blur-sm'>
                    <p className='text-2xl'>{item.name}</p>
                  </div>
                  <div className='absolute top-0 left-0 w-full h-full hidden group-hover:flex justify-center items-center bg-slate-700 bg-opacity-10 backdrop-blur-sm transition-all duration-200'>
                    <span className='text-2xl font-bold text-white'>Learn More</span>
                  </div>
                </div>
              );
            })}
          </div>
          {/* <div className='w-full text-left'>
            <a href='/cleaning-services'>
              <button className='bg-slate-600 p-3 mt-6 shadow-lg hover:bg-slate-500 transition-all rounded text-white'>Find more services...</button>
            </a>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default ServiceSection;
