import React from 'react';
import { BsAwardFill, BsCheckCircleFill, BsFillTelephoneFill } from 'react-icons/bs';
import { AiFillHome, AiFillMessage } from 'react-icons/ai';
import { IoMdMail } from 'react-icons/io';

const ContactSection = () => {
  return (
    <section id='contact' className='pt-20 pb-24 bg-gray-50 relative'>
      {/* <div className='bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20' style={{ height: '80px' }}>
        <svg
          className='absolute bottom-0 overflow-hidden'
          xmlns='http://www.w3.org/2000/svg'
          preserveAspectRatio='none'
          version='1.1'
          viewBox='0 0 2560 100'
          x='0'
          y='0'
        >
          <polygon className='text-gray-300 fill-current' points='2560 0 2560 100 0 100'></polygon>
        </svg>
      </div> */}
      <div className='container mx-auto px-4'>
        <div className='flex flex-wrap justify-center text-center mb-10'>
          <div className='w-full lg:w-6/12 px-4'>
            <h2 className='text-4xl font-semibold'>Why Choose DHS Technology?</h2>
            <p className='text-lg leading-relaxed m-4 text-gray-600'></p>
          </div>
        </div>
        <div className='flex flex-wrap justify-center mb-10'>
          <div className='w-full md:w-3/12 px-4 text-center'>
            <div className='text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center'>
              <BsAwardFill size={'1.5rem'} className={'fill-mainColor'} />
            </div>
            <h6 className='text-xl mt-5 font-semibold text-gray-800'>Team of Experience Engineers</h6>
            <p className='mt-2 mb-4 text-gray-600'>
              Our team of engineer have 15 years of combined experience in System and Network Design, Web developnmet , digital Marketing and IT Support. We aim
              to provide quality service and high customer satisfaction.
            </p>
          </div>
          <div className='w-full md:w-3/12 px-4 text-center'>
            <div className='text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center'>
              <AiFillMessage size={'1.5rem'} className={'fill-mainColor'} />
            </div>
            <h5 className='text-xl mt-5 font-semibold text-gray-800'>Customer Based Support</h5>
            <p className='mt-2 mb-4 text-gray-600'>
              We tailor our support based on your requirement to achieve best outcome for your business. We strive for efficency and high productivity for your
              organization
            </p>
          </div>
          <div className='w-full md:w-3/12 px-4 text-center'>
            <div className='text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center'>
              <AiFillHome size={'1.5rem'} className={'fill-mainColor'} />
            </div>
            <h5 className='text-xl mt-5 font-semibold text-gray-800'>Australian based organization</h5>
            <p className='mt-2 mb-4 text-gray-600'>
              Being 100% Aussie based company, we understand your business process and can deliver on time with high value.
            </p>
          </div>
          <div className='w-full md:w-3/12 px-4 text-center'>
            <div className='text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center'>
              <BsCheckCircleFill size={'1.5rem'} className={'fill-mainColor'} />
            </div>
            <h5 className='text-xl mt-5 font-semibold text-gray-800'>Reliale and flexible support</h5>
            <p className='mt-2 mb-4 text-gray-600'>
              We provide 24/7 support for your business so you can sit back and relax on weekends and afterhours. We provide upfront Quoatation for the service
              we provide without a Fuss.
            </p>
          </div>
        </div>
        <div className='w-full md:w-12/12 xl:w-8/12 mx-auto'>
          {/* <ContactForm heading={'Lets Get in Touch!'} /> */}

          <div className='flex flex-wrap px-5 md:px-10 pt-8 pb-16 bg-slate-300 rounded-lg'>
            <h3 className='text-3xl md:text-4xl w-full text-center mb-8 font-bold leading-normal'>Start Your IT Consultation Today</h3>
            <div className='flex flex-col md:flex-row gap-10 items-center justify-center'>
              <div className='w-full md:w-6/12 mx-auto'>
                <p className='text-lg'>Get in touch touch with us and we will response to you at our earliest possible. Thank You!</p>
              </div>
              <div className='w-full md:w-6/12 mx-auto'>
                <div className='w-full mb-5'>
                  <a
                    href='tel: +61279048899'
                    className='text-lg font-semibold text-black hover:text-slate-900 hover:underline hover:underline-offset-4 transition-all'
                  >
                    <BsFillTelephoneFill size={'2rem'} className='inline fill-mainColor mr-2' /> 02 7904 8899
                  </a>
                </div>
                <div className='w-full mb-3'>
                  <a
                    href='mailto: itsupport@dhstechnology.com.au'
                    className='text-lg font-semibold text-black hover:text-slate-900 hover:underline hover:underline-offset-4 transition-all'
                  >
                    <IoMdMail size={'2rem'} className='inline fill-mainColor mr-2' /> itsupport@dhstechnology.com.au
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
