import React, { useState } from 'react';
import SocialLinks from '../utils/socialLinks';
import { GoThreeBars } from 'react-icons/go';
import { IoClose } from 'react-icons/io5';

const Header = (props) => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const { active } = props;
  return (
    <nav className='px-2 py-5 bg-sky-600 text-black top-0 w-full flex flex-wrap items-center justify-center z-50'>
      <div className='container mx-auto flex items-center justify-between gap-2'>
        <div className='w-full relative flex justify-between items-center md:w-auto md:static md:block md:justify-start px-2'>
          <a href='/' className='flex flex-shrink-0 flex-col justify-start items-center'>
            <img src='/assets/main_logo.png' alt='LOGO' className='w-[200px] h-[50px]'></img>
            <p className='text-[0.6rem] text-black -mt-2'>Simple and Secure Technology</p>
          </a>
          <button
            className='cursor-pointer px-2 py-2 h-10 mt-0 border border-solid border-slate-200 rounded hover:bg-slate-200 transition-all duration-500 block md:hidden outline-none focus:outline-none'
            type='button'
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <GoThreeBars size={'1.2rem'} />
          </button>
        </div>
        <div
          className={
            'flex flex-col md:flex-row absolute md:relative flex-grow md:items-center bg-sky-600 md:bg-transparent md:shadow-none right-0 top-0 md:h-auto h-screen md:w-auto transition-all duration-500 md:translate-x-0 z-50 ' +
            (navbarOpen ? 'rounded shadow-lg flex' : 'w-0 hidden md:flex')
          }
        >
          <button className='mr-auto p-3 md:hidden' onClick={() => setNavbarOpen(!navbarOpen)}>
            <IoClose size={'1.5rem'} className={'hover:text-red-400 transition-all duration-500'} />
          </button>
          <ul className='flex flex-col md:flex-row list-none md:mr-auto content-start uppercase font-semibold text-lg '>
            <li className={'flex justify-center ' + (active === 'home' && 'border-l-4 md:border-b-2 md:border-l-0 border-cyan-900')}>
              <a href={'/'} className='px-3 py-2 w-full text-center rounded transition ease-in-out duration-500'>
                Home
              </a>
            </li>
            <li className={'flex justify-center ' + (active === 'home' && 'border-l-4 md:border-b-2 md:border-l-0 border-cyan-900')}>
              <a href={'/#about'} className='px-3 py-2 w-full text-center rounded transition ease-in-out duration-500'>
                About US
              </a>
            </li>
            <li className={'flex justify-center ' + (active === 'service' && 'border-l-4 md:border-b-2 md:border-l-0 border-cyan-900')}>
              <a href={'/services/system-and-network-design'} className='px-3 py-2 w-full text-center rounded transition ease-in-out duration-500'>
                Services
              </a>
            </li>
            <li className={'flex justify-center ' + (active === 'contact' && 'border-l-4 md:border-b-2 md:border-l-0 border-cyan-900')}>
              <a href={'/#contact'} className='px-3 py-2 w-full text-center rounded transition ease-in-out duration-500'>
                Contact Us
              </a>
            </li>
          </ul>
          <div className='mt-5 md:mt-0 flex justify-center'>
            <SocialLinks />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
