import React from 'react';
import { BsFillTelephoneFill } from 'react-icons/bs';

const SocialLinks = () => {
  return (
    <ul className='flex items-center list-none'>
      <li className='flex items-center'>
        <a
          className={' hover:text-slate-800 transition-all duration-400  px-2 py-2 flex items-center justify-center gap-2'}
          href='tel:+61279048899'
          target={'_blank'}
          rel={'noreferrer noopener'}
        >
          <BsFillTelephoneFill size={'1.5rem'} />
          <span className='text-xl font-bold underline underline-offset-2'>02 7904 8899</span>
        </a>
      </li>
    </ul>
  );
};

export default SocialLinks;
