import React from 'react';

const Modal = ({ service }) => {
  return (
    <div className={'p-5'}>
      <div className='flex flex-col justify-center items-center gap-5'>
        <h2 className='text-3xl px-2 font-bold underline underline-offset-4 decoration-mainColor'>{service?.name}</h2>
        <p className='text-justify text-lg'>{service?.description}</p>
      </div>
    </div>
  );
};

export default Modal;
