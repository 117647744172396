import React, { useEffect, useState } from 'react';
import AVAILABLE_SERVICES from '../data/availableServices.json';
import Header from '../main_page/header';
import Footer from '../main_page/footer';
import { useNavigate, useParams } from 'react-router-dom';

const ServicePage = () => {
  const [service, setService] = useState(null);
  const { serviceID } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const findService = AVAILABLE_SERVICES.find((item) => item.id === serviceID) || {};

    setService({ ...findService });
  }, [serviceID]);

  return (
    <>
      <Header active={'service'} />
      {/* <PageHeading heading={'Our Services'} icon={'services'} image={'/assets/illustrations/serviceIllus.svg'} /> */}
      <div className='mt-8 mb-12'>
        <div className='container mx-auto'>
          <div className='flex flex-wrap justify-center items-center gap-5 mb-10'>
            {AVAILABLE_SERVICES &&
              AVAILABLE_SERVICES.map((item, i) => (
                <div
                  key={i}
                  className='h-64 max-w-[18rem] w-full sm:w-6/12 md:w-4/12 lg:w-3/12 px-4 md:px-0 cursor-pointer'
                  onClick={() => navigate(`/services/${item?.id}`)}
                >
                  <div className='relative h-full flex flex-col min-w-0 break-words w-full shadow-md rounded-lg bg-mainColor hover:-translate-y-2 hover:shadow-xl transition-all duration-500'>
                    <img alt='...' src={item?.image} className='w-full h-3/5 object-cover align-middle rounded-t-lg' />
                    <div className='relative p-4 h-2/5'>
                      <div className='h-full group flex justify-between items-center'>
                        <h4 className='w-full text-xl text-center font-bold text-white'>{item.name}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className='flex flex-wrap justify-center items-center'>
            {service && service.id ? (
              <div className='flex flex-col gap-5 p-5'>
                <h2 className='text-2xl font-bold underline underline-offset-4'>{service?.name}</h2>
                {Object.entries(service?.description).map(([head, desc], index) => (
                  <div key={index}>
                    <h3 className='text-xl font-semibold'>{head}</h3>
                    <p className=''>{desc}</p>
                  </div>
                ))}
              </div>
            ) : (
              <h2 className='text-2xl font-bold'>Service Unavailable</h2>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ServicePage;
